import * as React from 'react';

const MiniEV = () => {
  const [hasMounted, setHasMounted] = React.useState(false);

  React.useEffect(() => {
    setHasMounted(true);
    window.location.replace(
      'https://www.ovoenergy.com/electric-cars/ev-tariff#miniOffer',
    );
  }, []);

  if (!hasMounted) {
    return null;
  }
  return null;
};

export default MiniEV;
